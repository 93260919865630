import React, { useRef, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import authSelectors from '../../redux/auth/selectors';
import tfaSelectors from '../../redux/tfa/selectors';
import Header from './Header';
import Footer from './Footer';
import Loader from '../Loader';
import routes from '../../routes';

export const dropListContext = createContext();

const MainLayout = ({ children }) => {
  const authLoading = useSelector(authSelectors.getLoading);

  const tfaLoading = useSelector(tfaSelectors.getLoading);

  const loading = authLoading || tfaLoading;

  return (
    <div className="wrapper">
      <main>{children}</main>
    </div>
  );
};

export default MainLayout;
