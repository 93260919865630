export default {
  // -----// LOGIN //-----//
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  // ----- // SIGNUP // ----- //
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  // -----// LOGOUT //-----//
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  // -----// LOGOUT //-----//
  GET_CURRENT_USER_START: 'GET_CURRENT_USER_START',
  GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE: 'GET_CURRENT_USER_FAILURE',
  // ----- // RECOVERY_PASSWORD // ----- //
  RECOVERY_PASSWORD_START: 'RECOVERY_PASSWORD_START',
  RECOVERY_PASSWORD_SUCCESS: 'RECOVERY_PASSWORD_SUCCESS',
  RECOVERY_PASSWORD_FAILURE: 'RECOVERY_PASSWORD_FAILURE',
  // ----- // FORGOT_PASSWORD // ----- //
  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  // ----- // CONFIRM_EMAIL // ----- //
  CONFIRM_EMAIL_START: 'CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
  // ----- // CHANGE_PASSWORD // ----- //
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  // -----// SET_MODAL //-----//
  SET_MODAL_START: 'SET_MODAL_START',
  SET_MODAL_SUCCESS: 'SET_MODAL_SUCCESS',
  SET_MODAL_FAILURE: 'SET_MODAL_FAILURE',

  CLOSE_LOADER: 'CLOSE_LOADER',

  GET_USERS_START: 'GET_USERS_START',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  BLOCK_USER_START: 'BLOCK_USER_START',
  BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
  BLOCK_USER_FAILURE: 'BLOCK_USER_FAILURE',

  DELETE_USER_START: 'DELETE_USER_START',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  GET_USER_ACTIVITY_START: 'GET_USER_ACTIVITY_START',
  GET_USER_ACTIVITY_SUCCESS: 'GET_USER_ACTIVITY_SUCCESS',
  GET_USER_ACTIVITY_FAILURE: 'GET_USER_ACTIVITY_FAILURE',

  GET_PERSONS_START: 'GET_PERSONS_START',
  GET_PERSONS_SUCCESS: 'GET_PERSONS_SUCCESS',
  GET_PERSONS_FAILURE: 'GET_PERSONS_FAILURE',

  GET_EVENTS_START: 'GET_EVENTS_START',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',

  DELETE_PERSONS_START: 'DELETE_PERSONS_START',
  DELETE_PERSONS_SUCCESS: 'DELETE_PERSONS_SUCCESS',
  DELETE_PERSONS_FAILURE: 'DELETE_PERSONS_FAILURE',

  DELETE_EVENTS_START: 'DELETE_EVENTS_START',
  DELETE_EVENTS_SUCCESS: 'DELETE_EVENTS_SUCCESS',
  DELETE_EVENTS_FAILURE: 'DELETE_EVENTS_FAILURE',

  BLOCK_PERSONS_START: 'BLOCK_PERSONS_START',
  BLOCK_PERSONS_SUCCESS: 'BLOCK_PERSONS_SUCCESS',
  BLOCK_PERSONS_FAILURE: 'BLOCK_PERSONS_FAILURE',

  BLOCK_EVENTS_START: 'BLOCK_EVENTS_START',
  BLOCK_EVENTS_SUCCESS: 'BLOCK_EVENTS_SUCCESS',
  BLOCK_EVENTS_FAILURE: 'BLOCK_EVENTS_FAILURE',

  GET_COLLECTIVES_START: 'GET_COLLECTIVES_START',
  GET_COLLECTIVES_SUCCESS: 'GET_COLLECTIVES_SUCCESS',
  GET_COLLECTIVES_FAILURE: 'GET_COLLECTIVES_FAILURE',

  BLOCK_COLLECTIVE_START: 'BLOCK_COLLECTIVE_START',
  BLOCK_COLLECTIVE_SUCCESS: 'BLOCK_COLLECTIVE_SUCCESS',
  BLOCK_COLLECTIVE_FAILURE: 'BLOCK_COLLECTIVE_FAILURE',

  GET_VENUES_START: 'GET_VENUES_START',
  GET_VENUES_SUCCESS: 'GET_VENUES_SUCCESS',
  GET_VENUES_FAILURE: 'GET_VENUES_FAILURE',

  BLOCK_VENUES_START: 'BLOCK_VENUES_START',
  BLOCK_VENUES_SUCCESS: 'BLOCK_VENUES_SUCCESS',
  BLOCK_VENUES_FAILURE: 'BLOCK_VENUES_FAILURE',

  GET_VERIF_REQ_START: 'GET_VERIF_REQ_START',
  GET_VERIF_REQ_SUCCESS: 'GET_VERIF_REQ_SUCCESS',
  GET_VERIF_REQ_FAILURE: 'GET_VERIF_REQ_FAILURE',

  CHANGE_VERIF_STATUS_START: 'CHANGE_VERIF_STATUS_START',
  CHANGE_VERIF_STATUS_SUCCESS: 'CHANGE_VERIF_STATUS_SUCCESS',
  CHANGE_VERIF_STATUS_FAILURE: 'CHANGE_VERIF_STATUS_FAILURE',

  GET_FEEDBACK_DATA_START: 'GET_FEEDBACK_DATA_START',
  GET_FEEDBACK_DATA_SUCCESS: 'GET_FEEDBACK_DATA_SUCCESS',
  GET_FEEDBACK_DATA_FAILURE: 'GET_FEEDBACK_DATA_FAILURE',

  LAYOUT_LOADER_START: 'LAYOUT_LOADER_START',
  LAYOUT_LOADER_STOP: 'LAYOUT_LOADER_STOP',
};
