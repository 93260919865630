import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReCaptcha from '../../components/ReCaptcha';
import { logIn, confirmEmail } from '../../redux/auth/operations';
import { validate } from './validate';
import { useInputsChange } from './hooks';
import withAuth from '../../HOC/withAuth';
import { closeModal } from '../../components/Modal';
import notification from '../../services/notification';
import routes from '../../routes';

const Login = () => {
  const dispatch = useDispatch();
  const [remember, setRemember] = useState(false);
  const [isRevealPass, setRevealPass] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [input, handleInputChange, handleClearInput, valid] = useInputsChange();
  const [validPass, validEmail] = valid;

  const handleSubmit = e => {
    e.preventDefault();
    const isValidCredentials = validate(valid);
    if (!isValidCredentials) return;
    if (!captcha) {
      notification({
        type: 'error',
        message: 'the recaptcha field is required!',
      });
      return;
    }
    const credentials = {
      ...input,
      remember,
      g_recaptcha_response: captcha,
    };

    dispatch(logIn(credentials));
  };

  const toggleReveal = () => {
    setRevealPass(prev => !prev);
  };

  const handleChangeRememberMe = () => {
    setRemember(prev => !prev);
  };

  const handleReCaptcha = e => {
    setCaptcha(e);
  };

  return (
    <section className="authorization-section">
      <div className="inside flex flex-c">
        <div className="authorization-right">
          <div className="authorization__form">
            <div className="authorization__title">
              <p>Sign In</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={`input ${!validEmail ? 'input--error' : ''}`}>
                <label>
                  <div className="input__inside">
                    <input
                      type="text"
                      placeholder="Enter your email address"
                      onChange={handleInputChange}
                      name="email"
                      value={input.email}
                    />
                  </div>
                  {!validEmail ? (
                    <div className="input__notification">
                      <p>Email invalid</p>
                    </div>
                  ) : (
                    ''
                  )}
                </label>
              </div>
              <div className={`input ${!validPass ? 'input--error' : ''}`}>
                <label>
                  <div className="input__inside">
                    <input
                      type={isRevealPass ? 'text' : 'password'}
                      placeholder="Enter your password"
                      onChange={handleInputChange}
                      name="password"
                      value={input.password}
                    />
                    <div
                      className="show-password"
                      onClick={toggleReveal}
                      role="presentation"
                    />
                  </div>
                  {!validPass ? (
                    <div className="input__notification">
                      <p>Password invalid</p>
                    </div>
                  ) : (
                    ''
                  )}
                </label>
              </div>

              <div className="authorization__line flex flex-m flex-sb">
                <div className="checkbox-wrap">
                  <label className="flex flex-m">
                    <input
                      type="checkbox"
                      className="hidden"
                      onClick={handleChangeRememberMe}
                    />
                    <div className="checkbox" />
                    <p>Remember me</p>
                  </label>
                </div>
                <div className="authorization__link">
                  <Link to={routes.FORGOT_PASS.path}>Forgot Password?</Link>
                </div>
              </div>
              <div className="input">
                <label>
                  <div className="input__inside">
                    <ReCaptcha
                      setCaptcha={handleReCaptcha}
                      captcha={!!captcha}
                    />
                  </div>
                </label>
              </div>
              <div className="button">
                <button type="submit">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withAuth(Login);
