import React from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../redux/store';
import types from '../../redux/types';
import authSelectors from '../../redux/auth/selectors';

export const openModal = Dom => {
  if (Dom) {
    store.dispatch({
      type: types.SET_MODAL_SUCCESS,
      payload: { status: true, Dom },
    });
    document.body.classList.add('no-scroll');
    return;
  }
  console.log('You forgot to transfer the dom element');
};

export const closeModal = () => {
  store.dispatch({
    type: types.SET_MODAL_SUCCESS,
    payload: { status: false, Dom: null },
  });
  document.body.classList.remove('no-scroll');
};

export const Modal = () => {
  const status = useSelector(store => store.modal.status);

  const dontCloseModal = useSelector(authSelectors.dontCloseModal);
  const Dom = useSelector(store => store.modal.Dom);
  const close = ({ target, currentTarget }) => {
    if (target === currentTarget) {
      closeModal(dontCloseModal);
    }
  };
  return (
    <>
      {status ? (
        <div className="modal-wrapper" onClick={close} role="presentation">
          <div
            className="modal-wrapper__inner"
            onClick={close}
            role="presentation"
          >
            {Dom ? <Dom /> : null}
          </div>
        </div>
      ) : null}
    </>
  );
};
