import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App';
import './styles/index.css';
import { store, persistor } from './redux/store';
import Loader from './components/Loader';

console.log(
  `%cBuild version: ${process.env.REACT_APP_VERSION || 'localhost'}`,
  'color: #0B6623; background: #000; padding: 15px 50px; font-size: 16px;',
);

export const Index = () => {
  return (
    <>
      <App />
      <NotificationContainer />
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
