import { combineReducers } from 'redux';
import types from '../types';

const events = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_EVENTS_SUCCESS:
      return payload;

    case types.BLOCK_EVENTS_SUCCESS:
      return {
        ...state,
        data: state.data.filter(e => {
          if (e.id === payload.id) {
            e.is_blocked = !e.is_blocked;
            return e;
          }
          return e;
        }),
      };

    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case types.GET_EVENTS_START:
      return true;
    case types.GET_EVENTS_SUCCESS:
      return false;
    case types.GET_EVENTS_FAILURE:
      return false;

    default:
      return state;
  }
};

export default combineReducers({ events, loading });
