import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
// axios.defaults.baseURL = "https://showmemory.com/api/admin";

// fix
// mj7zqacyd4fj

export default {
  auth: {
    login: data => axios.post(`/login`, data),
    getCurrentUser: () => axios.get('/'),
    logout: () => axios.post('/logout'),
    confirmEmail: data => axios.put(`/email_confirmation`, data),
    resetPassword: body => axios.post('/forgot_password/email', body),
    recoveryPassword: body => axios.put('/forgot_password/reset', body),
    editInfo: body => axios.post('/settings/edit_data', body),
    editPassword: body => axios.post('/settings/change_password', body),
    changeUserName: body => axios.post('/settings/change_username', body),
  },
  users: {
    getUsers: (page = 1) =>
      axios.get(`/management/users?pagination=7&page=${page}`),
    blockUser: id => axios.put(`/management/users/${id}/block_switch`),
    deleteUser: id => axios.delete(`/management/users/${id}/delete`),
    getUserActivity: (id, page = 1) =>
      axios.get(`/management/users/${id}/activity?page=${page}`),
    getVerifRequests: (page = 1) =>
      axios.get(`/management/users/verifications?page=${page}&paginate=7`),
    changeStatusOfVerification: body =>
      axios.post(`/management/users/verifications/verify`, body),
  },
  persons: {
    getPersons: (page = 1) =>
      axios.get(`/management/persons?paginate=7&page=${page}`),
    deletePerson: id => axios.delete(`/management/persons/${id}/delete`),
    blockPerson: id => axios.post(`/management/persons/${id}/block_switch`),
  },

  collectives: {
    getCollectives: (page = 1) =>
      axios.get(`/management/collectives?paginate=7&page=${page}`),
    blockCollective: id =>
      axios.post(`/management/collectives/${id}/block_switch`),
  },
  events: {
    getEvents: (page = 1, type) =>
      axios.get(`/management/events?paginate=7&page=${page}&type=${type}`),
    blockEvents: id => axios.post(`/management/events/${id}/block_switch`),
  },
  venues: {
    getVenues: (page = 1, type) =>
      axios.get(`/management/venues?paginate=7&page=${page}&type=${type}`),
    blockVenues: id => axios.post(`/management/venues/${id}/block_switch`),
  },
  feedback: {
    getFeedbackList: (page = 1) =>
      axios.get(`/management/feedbacks?page=${page}`),
    getSingleFeedBackDetail: id => axios.get(`/management/feedbacks/${id}`),
    feedbackUpdate: (id, data) =>
      axios.post(`/management/feedbacks/${id}/update`, data),
  },
};
