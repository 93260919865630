import moment from 'moment';

export const passwordValid = password => {
  if (password.length < 8) return false;
  const regExp = /(?=^.\S{7,}$)((?=.*\d)|(?=.*\W+))(?=.*[0-9])(?![.\n])(?=.*[A-Za-z0-9])(?=.*[~!^(){}<>%@#&*+.,=_-]).*$/;
  if (!regExp.test(password)) return false;
  return true;
};

export const emailValid = email => {
  const regExp = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+.[a-zA-Z]{2,4}/;
  if (!regExp.test(email)) return false;
  return true;
};

export const phoneValid = phone => {
  //  eslint-disable-next-line
  const regExp = /^[\+][1-9]{1}[\d]{9,13}$/;
  if (!regExp.test(phone)) return false;
  return true;
};

const hostname = window?.location?.hostname;
const protocol = window?.location?.protocol;

export const wsUrl = () => {
  if (hostname === '127.0.0.1') {
    return `ws://${hostname}:8980`;
  }
  if (protocol === 'http:' && hostname !== 'localhost') {
    return `ws://${hostname}:8850`;
  }
  if (protocol === 'https:') {
    return `wss://${hostname}:8443`;
  }
  return 'ws://3.230.247.36:8850';
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const transformData = (time, format) => {
  if (typeof time === 'valueber') {
    return moment(time * 1000).format(format || 'DD-MM-YYYY HH:mm:ss');
  }
  return time;
};

export const toUpdate = (first, second) => {
  try {
    let newSecond = second;
    const arr = Object.entries(first);
    arr.forEach(element => {
      const key = element[0];
      const value = element[1];
      if (newSecond[key] === undefined) {
        newSecond = { ...newSecond, [key]: value };
        return;
      }
      if (typeof value === 'object' && !value?.length && value !== null) {
        return toUpdate(value, newSecond[key]);
      }
      newSecond[key] = value;
    });
    return newSecond;
  } catch (error) {
    console.log('catch', error);
    return { ...second, ...first };
  }
};

export const reqUrlForFiles = () => {
  if (hostname === '127.0.0.1') {
    return `http://${hostname}`;
  }
  if (protocol === 'http:' && hostname !== 'localhost') {
    return `http://${hostname}`;
  }
  if (protocol === 'https:') {
    return `https://${hostname}`;
  }
  return 'http://50.17.139.51:8802';
};

export const formatDate = date => {
  if (!date) return;
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

function DaysInMonth(date2_UTC) {
  const monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
  const monthEnd = new Date(
    date2_UTC.getFullYear(),
    date2_UTC.getMonth() + 1,
    1,
  );
  const monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
  return monthLength;
}

export function getPeriodInMinutes(date_1, date_2) {
  // convert to UTC
  const date2_UTC = new Date(
    Date.UTC(
      date_2.getUTCFullYear(),
      date_2.getUTCMonth(),
      date_2.getUTCDate(),
    ),
  );
  const date1_UTC = new Date(
    Date.UTC(
      date_1.getUTCFullYear(),
      date_1.getUTCMonth(),
      date_1.getUTCDate(),
    ),
  );
  let yAppendix;
  let mAppendix;
  let dAppendix;
  //--------------------------------------------------------------
  const seconds = date2_UTC.getSeconds() - date1_UTC.getSeconds();
  //--------------------------------------------------------------
  const minutes = date2_UTC.getMinutes() - date1_UTC.getMinutes();
  //--------------------------------------------------------------
  const hours = date2_UTC.getHours() - date1_UTC.getHours();
  //--------------------------------------------------------------
  let days = date2_UTC.getDate() - date1_UTC.getDate();
  if (days < 0) {
    date2_UTC.setMonth(date2_UTC.getMonth() - 1);
    days += DaysInMonth(date2_UTC);
  }
  //--------------------------------------------------------------
  let months = date2_UTC.getMonth() - date1_UTC.getMonth();
  if (months < 0) {
    date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
    months += 12;
  }
  //--------------------------------------------------------------
  const years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
  if (years > 1) yAppendix = ' years';
  else yAppendix = ' year';
  if (months > 1) mAppendix = ' months';
  else mAppendix = ' month';
  if (days > 1) dAppendix = ' days';
  else dAppendix = ' day';
  if (years < 0 || months < 0 || days < 0) {
    return 'the term has expired';
  }
  return {
    days: parseInt(days),
    hours,
    minutes,
    seconds,
  };
}

export const monthList = [
  { label: 'Jan', value: 1, name: 'exp_month' },
  { label: 'Feb', value: 2, name: 'exp_month' },

  { label: 'Mar', value: 3, name: 'exp_month' },
  { label: 'Apr', value: 4, name: 'exp_month' },
  { label: 'May', value: 5, name: 'exp_month' },

  { label: 'Jun', value: 6, name: 'exp_month' },
  { label: 'Jul', value: 7, name: 'exp_month' },
  { label: 'Aug', value: 8, name: 'exp_month' },

  { label: 'Sep', value: 9, name: 'exp_month' },
  { label: 'Oct', value: 10, name: 'exp_month' },
  { label: 'Nov', value: 11, name: 'exp_month' },

  { label: 'Dec', value: 12, name: 'exp_month' },
];

const date = new Date();
export const getCurrentDate = () => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return [month, year];
};
const [currentMonth, currentYear] = getCurrentDate();
export const yearOptions = [
  { value: currentYear, label: currentYear, name: 'exp_year' },
  { value: currentYear + 1, label: currentYear + 1, name: 'exp_year' },
  { value: currentYear + 2, label: currentYear + 2, name: 'exp_year' },
  { value: currentYear + 3, label: currentYear + 3, name: 'exp_year' },
  { value: currentYear + 4, label: currentYear + 4, name: 'exp_year' },
  { value: currentYear + 5, label: currentYear + 5, name: 'exp_year' },
];

export const imgTypes = ['image/png', 'image/jpeg'];
export const verificationImgTypes = ['image/jpeg'];

export const formatedDate = date => {
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  return [year, month, day].join('-');
};

export const formatedDateWithTime = date => {
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }
  if (hours.length < 2) {
    hours = `0${hours}`;
  }
  if (minutes.length < 2) {
    minutes = `0${minutes}`;
  }
  return `${[year, month, day].join('-')} ${hours}:${minutes}`;
};

export const getCategoriesName = categories => {
  if (categories) {
    const names = categories.reduce((allCategories, categorie) => {
      allCategories.push(categorie.name);
      return allCategories;
    }, []);
    return names;
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
  });
};

export const formatCategories = (categories, section) => {
  // const sportCategories = categories.filter(e => e.type === section.SPORT);
  // const athleteCategories = categories.filter(e => e.type === section.ATHLETE);
  // const artistCategories = categories.filter(e => e.type === section.ARTIST);
  // return {
  //   [section.SPORT]: getCategoriesName(sportCategories),
  //   [section.ATHLETE]: getCategoriesName(athleteCategories),
  //   [section.ARTIST]: getCategoriesName(artistCategories),
  // };
};

// Update the count down every 1 second
// const x = setInterval(function () {
//   // Get today's date and time
//   const now = new Date().getTime();

//   // Find the distance between now and the count down date
//   const distance = countDownDate - now;

//   //Time calculations for days, hours, minutes and seconds
//   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
//   const hours = Math.floor(
//     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
//   );
//   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//   const seconds = Math.floor((distance % (1000 * 60)) / 1000);

//   //Output the result in an element with id="demo"
//   document.getElementById(
//     'demo',
//   ).innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s `;
//   //If the count down is over, write some text
//   if (distance < 0) {

//     clearInterval(x);
//   }
// }, 1000);
const editText = text => {
  let newText = '';
  const arrayText = text.split('_');
  const firstWord = arrayText[0][0].toUpperCase() + arrayText[0].slice(1);

  for (let i = 0; i < arrayText.length; i += 1) {
    if (i > 0) {
      newText += ` ${arrayText[i]}`;
    } else {
      newText += firstWord;
    }
  }
  return newText;
};
export const getErrorText = array => {
  if (!array) return 'something went wrong';
  const keys = Object.keys(array);

  if (typeof array[keys] === 'string') {
    return editText(array[keys]);
  }
  return editText(array[keys][0]);
};
