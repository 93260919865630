import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import errorSelectors from '../../redux/error/selectors';

const ReCapchaComponent = ({ setCaptcha, captcha }) => {
  const recaptchaRef = useRef(null);
  const authError = useSelector(errorSelectors.getError);

  const handleResetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  const handleSetCaptcha = e => {
    setCaptcha(e);
  };

  useEffect(() => {
    if (captcha || !authError) return;
    handleResetCaptcha();
  }, [captcha, authError]);

  useEffect(() => {
    if (!authError) return;
    handleResetCaptcha();
  }, [authError]);

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey="6LcjJ8MZAAAAANwDjOso9fFNtKbKN87lDcGag9IM"
      onChange={handleSetCaptcha}
    />
  );
};

ReCapchaComponent.propTypes = {
  captcha: PropTypes.bool.isRequired,
  setCaptcha: PropTypes.func.isRequired,
};

export default ReCapchaComponent;
// "proxy": "http://127.0.0.1:8810",
