import React, { StrictMode, Suspense, useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authOperations from '../../redux/auth/operations';
import PrivateRoute from '../../HOC/PrivateRoute';
import ErrorNotification from '../Notifications/Error';
import { Modal } from '../Modal';
import Loader from '../Loader';
import MainLayout from '../MainLayout';
import routes from '../../routes';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authOperations.getCurrentUser());
  }, [dispatch]);

  return (
    <>
      <Modal />
      <StrictMode>
        <Suspense fallback={<Loader />}>
          <MainLayout>
            <Loader />
            <Switch>
              <PrivateRoute
                exact
                path={routes.USER_MANAGEMENT.path}
                component={routes.USER_MANAGEMENT.component}
                render={() => {
                  return <Redirect to={routes.USER_MANAGEMENT.path} />;
                }}
              />

              <Route
                exact
                path={routes.LOGIN.path}
                component={routes.LOGIN.component}
              />

              <Route
                exact
                path={routes.RECOVERY_PASS.path}
                component={routes.RECOVERY_PASS.component}
              />
              <Route
                exact
                path={routes.FORGOT_PASS.path}
                component={routes.FORGOT_PASS.component}
              />
              <PrivateRoute
                exact
                path={routes.LAST_ACTIVITY.path}
                component={routes.LAST_ACTIVITY.component}
              />
              <PrivateRoute
                exact
                path={routes.PERSONALITY_MANAGEMENT.path}
                component={routes.PERSONALITY_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.COLLECTIVES_MANAGEMENT.path}
                component={routes.COLLECTIVES_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.SHOWS_MANAGEMENT.path}
                component={routes.SHOWS_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.EVENTS_MANAGEMENT.path}
                component={routes.EVENTS_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.VENUES_MANAGEMENT.path}
                component={routes.VENUES_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.VERIFICATION_MANAGEMENT.path}
                component={routes.VERIFICATION_MANAGEMENT.component}
              />
              <PrivateRoute
                exact
                path={routes.FEEDBACKS.path}
                component={routes.FEEDBACKS.component}
              />
              <PrivateRoute
                exact
                path={routes.FEEDBACK_DETAIL.path}
                component={routes.FEEDBACK_DETAIL.component}
              />
              <Redirect to={routes.USER_MANAGEMENT.path} />
            </Switch>
            <ErrorNotification />
          </MainLayout>
        </Suspense>
      </StrictMode>
    </>
  );
};

export default App;
