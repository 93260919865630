import React from 'react';
import { PointSpreadLoading } from 'react-loadingg';
import { useDispatch, useSelector } from 'react-redux';
import selectorEvents from '../../redux/events/selectors';
import selectorPersons from '../../redux/persons/selectors';
import selectorUsers from '../../redux/users/selectors';
import selectorVenues from '../../redux/venues/selectors';
import SelectorsLayout from '../../redux/layout/selectors';
import { useEffect } from 'react';

const Loader = () => {
  const eventLoading = useSelector(selectorEvents.getLoading);
  const personsLoading = useSelector(selectorPersons.getLoading);
  const usersLoading = useSelector(selectorUsers.getLoading);
  const venuesLoading = useSelector(selectorVenues.getLoading);
  const layoutLoading = useSelector(SelectorsLayout.getLoading);

  const loading =
    eventLoading ||
    personsLoading ||
    usersLoading ||
    venuesLoading ||
    layoutLoading;
  return <>{loading && <PointSpreadLoading />}</>;
};

export default Loader;
