const getUsers = state => state.users.users?.data;
const getUserActivities = state => state.users.userActivity;
const getLastPageOfUsers = state => state.users.users?.last_page;
const getLastPageOfVerifReqs = state => state.users.verifReqs?.last_page;
const getVerifReqs = state => state.users.verifReqs;
const getLoading = state => state.users.loading;

export default {
  getUsers,
  getLastPageOfUsers,
  getLoading,
  getUserActivities,
  getVerifReqs,
  getLastPageOfVerifReqs,
};
