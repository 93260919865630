import { combineReducers } from 'redux';
import types from '../types';

const users = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_USERS_SUCCESS:
      return { ...payload.users };
    case types.DELETE_USER_SUCCESS:
      return { ...state, data: state.data.filter(e => e.id !== payload.id) };
    case types.BLOCK_USER_SUCCESS:
      return {
        ...state,
        data: state.data.map(e => (e.id === payload.id ? payload.data : e)),
      };

    default:
      return state;
  }
};

const userActivity = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_USER_ACTIVITY_SUCCESS:
      return { ...payload };

    default:
      return state;
  }
};

const verifReqs = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_VERIF_REQ_SUCCESS:
      return { ...payload };

    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case types.GET_USERS_START:
      return true;
    case types.GET_USERS_SUCCESS:
      return false;
    case types.GET_USERS_FAILURE:
      return false;

    case types.GET_VERIF_REQ_START:
      return true;
    case types.GET_VERIF_REQ_SUCCESS:
      return false;
    case types.GET_VERIF_REQ_FAILURE:
      return false;

    default:
      return state;
  }
};

export default combineReducers({ users, userActivity, loading, verifReqs });
