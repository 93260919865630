const isAuthenticated = state => !!state.auth.token;
const getLoading = state => state.auth.loading;
const getUser = state => state.auth.user;
const dontCloseModal = state => state.auth.dontCloseModal;
export default {
  isAuthenticated,
  getLoading,
  getUser,
  dontCloseModal,
};
