import { combineReducers } from 'redux';
import types from '../types';

const loading = (state = false, { type }) => {
  switch (type) {
    case types.ENABLE_TFA_START:
      return true;
    case types.ENABLE_TFA_SUCCESS:
      return false;
    case types.ENABLE_TFA_FAILURE:
      return false;

    case types.DISABLE_TFA_START:
      return true;
    case types.DISABLE_TFA_SUCCESS:
      return false;
    case types.DISABLE_TFA_FAILURE:
      return false;

    case types.GET_SECRET_KEY_START:
      return true;
    case types.GET_SECRET_KEY_SUCCESS:
      return false;
    case types.GET_SECRET_KEY_FAILURE:
      return false;

    case types.UPDATE_PHONE_TFA_START:
      return true;
    case types.UPDATE_PHONE_TFA_SUCCESS:
      return false;
    case types.UPDATE_PHONE_TFA_FAILURE:
      return false;

    default:
      return state;
  }
};

export default combineReducers({ loading });
