import { lazy } from 'react';

const routes = {
  USER_MANAGEMENT: {
    path: `/user-management`,
    component: lazy(() =>
      import(
        '../pages/UserManagement' /* webpackChunkName: "UserManagement" */
      ),
    ),
  },

  PERSONALITY_MANAGEMENT: {
    path: `/personality-management`,
    component: lazy(() =>
      import(
        '../pages/PersonalityManagement' /* webpackChunkName: "PersonalityManagement" */
      ),
    ),
  },

  SHOWS_MANAGEMENT: {
    path: `/shows-management`,
    component: lazy(() =>
      import(
        '../pages/ShowsManagement' /* webpackChunkName: "ShowsManagement" */
      ),
    ),
  },

  EVENTS_MANAGEMENT: {
    path: `/events-management`,
    component: lazy(() =>
      import(
        '../pages/EventsManagement' /* webpackChunkName: "EventsManagement" */
      ),
    ),
  },

  COLLECTIVES_MANAGEMENT: {
    path: `/collectives-management`,
    component: lazy(() =>
      import(
        '../pages/CollectivesManagement' /* webpackChunkName: "CollectivesManagement" */
      ),
    ),
  },

  VENUES_MANAGEMENT: {
    path: `/venues-management`,
    component: lazy(() =>
      import(
        '../pages/VenuesManagement' /* webpackChunkName: "VenuesManagement" */
      ),
    ),
  },

  VERIFICATION_MANAGEMENT: {
    path: `/verification-management`,
    component: lazy(() =>
      import(
        '../pages/VerificationManagement' /* webpackChunkName: "VerificationManagement" */
      ),
    ),
  },

  LOGIN: {
    path: `/login`,
    component: lazy(() =>
      import('../pages/Login' /* webpackChunkName: "Login" */),
    ),
  },

  RECOVERY_PASS: {
    path: `/recovery_password`,
    component: lazy(() =>
      import('../pages/RecoveryPass' /* webpackChunkName: "RecoveryPass" */),
    ),
  },

  FORGOT_PASS: {
    path: `/forgot-pass`,
    component: lazy(() =>
      import(
        '../pages/ForgotPassword' /* webpackChunkName: "ForgotPassword" */
      ),
    ),
  },
  LAST_ACTIVITY: {
    path: `/last-activity/:id`,
    shortPath: `/last-activity`,
    component: lazy(() =>
      import(
        '../pages/UserManagement/LastActivity' /* webpackChunkName: "ForgotPassword" */
      ),
    ),
  },
  FEEDBACKS: {
    path: `/feedbacks`,
    shortPath: `/feedbacks`,
    component: lazy(() =>
      import('../pages/Feedbacks' /* webpackChunkName: "ForgotPassword" */),
    ),
  },
  FEEDBACK_DETAIL: {
    path: `/feedbacks-details/:id`,
    shortPath: `/feedbacks-details`,
    component: lazy(() =>
      import(
        '../pages/Feedbacks/FeedbackDetails' /* webpackChunkName: "ForgotPassword" */
      ),
    ),
  },
};
export default routes;
