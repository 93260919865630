import types from '../types';

const loading = (state = false, { type }) => {
  switch (type) {
    case types.LAYOUT_LOADER_START:
      return true;
    case types.LAYOUT_LOADER_STOP:
      return false;

    default:
      return state;
  }
};

export default loading;
