import types from '../types';

const modal = (state = { status: false, Dom: null }, { type, payload }) => {
  switch (type) {
    case types.SET_MODAL_SUCCESS:
      return payload;
    default:
      return state;
  }
};

export default modal;
