import { combineReducers } from 'redux';
import types from '../types';

const user = (state = null, { type, payload }) => {
  switch (type) {
    case types.LOGIN_SUCCESS:
      return { ...state, ...payload.userData };
    case types.GET_CURRENT_USER_SUCCESS:
      return { ...state, ...payload };
    case types.EDIT_INFO_SUCCESS:
      return { ...state, ...payload.data };
    case types.LOGOUT_SUCCESS:
      return null;

    default:
      return state;
  }
};

const token = (state = null, { type, payload }) => {
  switch (type) {
    case types.LOGIN_SUCCESS:
      return payload.token;
    case types.GOOGLE_AUTH_SUCCESS:
      return payload.token;
    case types.LOGOUT_SUCCESS:
      return null;

    default:
      return state;
  }
};

const dontCloseModal = (state = false, { type, payload }) => {
  switch (type) {
    case types.OPENED_NICKNAME_MODAL:
      return true;
    case types.CLOSED_NICKNAME_MODAL:
      return false;

    case types.OPENED_PHONE_MODAL:
      return true;
    case types.CLOSED_PHONE_MODAL:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case types.LOGIN_START:
      return true;
    case types.LOGIN_SUCCESS:
      return false;
    case types.LOGIN_FAILURE:
      return false;

    case types.SIGNUP_START:
      return true;
    case types.SIGNUP_SUCCESS:
      return false;
    case types.SIGNUP_FAILURE:
      return false;

    case types.LOGOUT_START:
      return true;
    case types.LOGOUT_SUCCESS:
      return false;
    case types.LOGOUT_FAILURE:
      return false;

    case types.GET_CURRENT_USER_START:
      return true;
    case types.GET_CURRENT_USER_SUCCESS:
      return false;
    case types.GET_CURRENT_USER_FAILURE:
      return false;

    case types.RECOVERY_PASSWORD_START:
      return true;
    case types.RECOVERY_PASSWORD_SUCCESS:
      return false;
    case types.RECOVERY_PASSWORD_FAILURE:
      return false;

    case types.FORGOT_PASSWORD_START:
      return true;
    case types.FORGOT_PASSWORD_SUCCESS:
      return false;
    case types.FORGOT_PASSWORD_FAILURE:
      return false;

    case types.CONFIRM_EMAIL_START:
      return true;
    case types.CONFIRM_EMAIL_SUCCESS:
      return false;
    case types.CONFIRM_EMAIL_FAILURE:
      return false;

    case types.EDIT_INFO_START:
      return true;
    case types.EDIT_INFO_SUCCESS:
      return false;
    case types.EDIT_INFO_FAILURE:
      return false;

    case types.EDIT_PASSWORD_START:
      return true;
    case types.EDIT_PASSWORD_SUCCESS:
      return false;
    case types.EDIT_PASSWORD_FAILURE:
      return false;

    case types.STORE_SENT_TX_START:
      return true;
    case types.STORE_SENT_TX_SUCCESS:
      return false;
    case types.STORE_SENT_TX_FAILURE:
      return false;

    case types.SEND_KYC_START:
      return true;
    case types.SEND_KYC_SUCCESS:
      return false;
    case types.SEND_KYC_FAILURE:
      return false;

    case types.CLOSE_LOADER:
      return false;

    default:
      return state;
  }
};

export default combineReducers({ user, token, loading, dontCloseModal });
