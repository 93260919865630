import { combineReducers } from 'redux';
import types from '../types';

const errorsInfo = (state = [], { type, payload }) => {
  switch (type) {
    case types.LOGIN_FAILURE:
      return payload;
    case types.SIGNUP_FAILURE:
      return payload;
    case types.LOGOUT_FAILURE:
      return payload;
    case types.GET_CURRENT_USER_FAILURE:
      return payload;
    case types.RECOVERY_PASSWORD_FAILURE:
      return payload;
    case types.FORGOT_PASSWORD_FAILURE:
      return payload;
    case types.CONFIRM_EMAIL_FAILURE:
      return payload;
    case types.EDIT_PASSWORD_FAILURE:
      return payload;
    case types.EDIT_INFO_FAILURE:
      return payload;
    case types.ENABLE_TFA_FAILURE:
      return payload;
    case types.DISABLE_TFA_FAILURE:
      return payload;
    case types.GET_SECRET_KEY_FAILURE:
      return payload;
    case types.UPDATE_PHONE_TFA_FAILURE:
      return payload;
    case types.GET_ALL_TOKENS_FAILURE:
      return payload;
    case types.GET_LATEST_TOKENS_FAILURE:
      return payload;
    case types.GET_VIEVED_TOKENS_FAILURE:
      return payload;
    case types.SHOW_TOKEN_FAILURE:
      return payload;
    case types.SEARCH_TOKENS_FAILURE:
      return payload;
    case types.PAY_BY_CARD_FAILURE:
      return payload;
    case types.GET_TRANSACTIONS_FAILURE:
      return payload;
    case types.GET_PURCHASED_TOKENS_FAILURE:
      return payload;
    case types.SUBSCRIBE_FAILURE:
      return payload;
    case types.REMIND_ME_FAILURE:
      return payload;
    case types.STORE_SENT_TX_FAILURE:
      return payload;
    case types.GET_TOKENS_BY_DATA_FAILURE:
      return payload;
    case types.SEND_KYC_FAILURE:
      return payload;
    case types.GET_CATEGORIES_FAILURE:
      return payload;
    case types.GET_CATEGORIES_FAILURE:
      return payload;
    case types.GET_CATEGORIES_FAILURE:
      return payload;
    default:
      return state;
  }
};

export default errorsInfo;
