import axios from 'axios';

import types from '../types';
import API from '../../services/api';
import notification from '../../services/notification';
import routes from '../../routes';
import { getErrorText } from '../../services/helpers';
import { closeModal, openModal } from '../../components/Modal';

export const token = {
  set(authToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  },
  unset() {
    axios.defaults.headers.common.Authorization = '';
  },
};

export const getCurrentUser = () => async (dispatch, getState) => {
  const {
    auth: { token: persistedToken },
  } = getState();

  if (!persistedToken) return;

  token.set(persistedToken);
  dispatch({ type: types.GET_CURRENT_USER_START });

  try {
    const { data, status } = await API.auth.getCurrentUser();
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.GET_CURRENT_USER_SUCCESS, payload: data });
  } catch (e) {
    dispatch({ type: types.LOGOUT_SUCCESS });
    dispatch({
      type: types.GET_CURRENT_USER_FAILURE,
      payload: e.response.data.errors,
    });
  }
};

export const authGoogle = (googleToken, closeModal) => async dispatch => {
  dispatch({ type: types.GOOGLE_AUTH_START });
  try {
    const { data, status } = await API.auth.authGoogle({ token: googleToken });

    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.GOOGLE_AUTH_SUCCESS, payload: data });
    token.set(data.token);
    closeModal();
    dispatch(getCurrentUser());
    notification({
      type: 'success',
      message: 'Login Success!',
    });
  } catch (e) {
    dispatch({
      type: types.GOOGLE_AUTH_FAILURE,
      payload: e.response.data.errors,
    });
  }
};

export const logIn = user => async dispatch => {
  dispatch({ type: types.LOGIN_START });

  try {
    const { data, status } = await API.auth.login(user);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');

    token.set(data.token);
    dispatch({ type: types.LOGIN_SUCCESS, payload: data });
    console.log(data.token, 'data in logini success');

    notification({
      type: 'success',
      message: 'Login Success!',
    });
  } catch (e) {
    dispatch({ type: types.LOGIN_FAILURE, payload: e.response.data.errors });
  }
};

export const logOut = () => async dispatch => {
  dispatch({ type: types.LOGOUT_START });
  try {
    await API.auth.logout();
    dispatch({ type: types.LOGOUT_SUCCESS });
    token.unset();
    notification({
      type: 'success',
      message: 'Logout success!',
    });
  } catch (e) {
    dispatch({
      type: types.LOGOUT_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const recoveryPass = (update, history) => async dispatch => {
  dispatch({ type: types.RECOVERY_PASSWORD_START });
  try {
    const { data, status } = await API.auth.recoveryPassword(update);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.RECOVERY_PASSWORD_SUCCESS });
    history.push(routes.LOGIN.path);

    notification({
      type: 'success',
      message: 'Password changed!',
    });
  } catch (e) {
    dispatch({
      type: types.RECOVERY_PASSWORD_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const resetPassword = (mail, history) => async dispatch => {
  dispatch({ type: types.FORGOT_PASSWORD_START });
  try {
    const { data, status } = await API.auth.resetPassword(mail);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.FORGOT_PASSWORD_SUCCESS });
    history.push(routes.LOGIN.path);
    notification({
      type: 'success',
      message: 'Sended!',
    });
  } catch (e) {
    dispatch({
      type: types.FORGOT_PASSWORD_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const confirmEmail = tokenData => async dispatch => {
  dispatch({ type: types.CONFIRM_EMAIL_START });
  try {
    const { data, status } = await API.auth.confirmEmail(tokenData);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.CONFIRM_EMAIL_SUCCESS });

    notification({
      type: 'success',
      message: 'Confirmed!',
    });
  } catch (e) {
    dispatch({
      type: types.CONFIRM_EMAIL_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const editInfo = content => async dispatch => {
  const body = new FormData();

  const keys = Object.keys(content);
  keys.forEach(key => {
    if (!content[key]) return;
    body.append(key, content[key]);
  });
  dispatch({ type: types.EDIT_INFO_START });
  try {
    const { data, status } = await API.auth.editInfo(body);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.EDIT_INFO_SUCCESS, payload: data });
    dispatch(getCurrentUser());
    notification({
      type: 'success',
      message: 'Successfully!',
    });
  } catch (e) {
    dispatch({
      type: types.EDIT_INFO_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const editPassword = (update, handleClearInput) => async dispatch => {
  dispatch({ type: types.EDIT_PASSWORD_START });
  try {
    const { status } = await API.auth.editPassword(update);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.EDIT_PASSWORD_SUCCESS });
    handleClearInput();
    notification({
      type: 'success',
      message: 'Successfully!',
    });
  } catch (e) {
    dispatch({
      type: types.EDIT_PASSWORD_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const verificateUser = (
  userData,
  ageProofFile,
  driverLicenseFile,
) => async dispatch => {
  const body = new FormData();

  const keys = Object.keys(userData);
  keys.map(key => {
    if (!userData[key]) return;
    body.append(key, userData[key]);
  });

  if (ageProofFile.length) {
    for (var i = 0; i < ageProofFile.length; i++) {
      body.append(`age_proof[${i}]`, ageProofFile[i]);
    }
  }
  if (driverLicenseFile.length) {
    for (var i = 0; i < driverLicenseFile.length; i++) {
      body.append(`driver_license[${i}]`, driverLicenseFile[i]);
    }
  }
  dispatch({ type: types.SEND_KYC_START });
  notification({
    type: 'success',
    message: 'Successfully!',
  });
  try {
    const { status } = await API.auth.sendKYC(body);
    if (status < 200 || status >= 300) {
      throw new Error('Something went wrong');
    }

    dispatch({ type: types.SEND_KYC_SUCCESS });
  } catch (e) {
    dispatch({ type: types.SEND_KYC_FAILURE, payload: e.response.data.errors });
  }
};

export const changeUserName = (update, closeModal) => async dispatch => {
  dispatch({ type: types.CHANGE_USERNAME_START });
  try {
    const { status } = await API.auth.changeUserName(update);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.CHANGE_USERNAME_SUCCESS });
    closeModal();
    dispatch({ type: types.CLOSED_NICKNAME_MODAL });
    dispatch(getCurrentUser());
    notification({
      type: 'success',
      message: 'Successfully!',
    });
  } catch (e) {
    notification({
      type: 'error',
      message: getErrorText(e?.response?.data?.errors),
    });
    dispatch({
      type: types.CHANGE_USERNAME_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};

export const joinNewsletters = body => async dispatch => {
  dispatch({ type: types.JOIN_NEWSLETTERS_START });
  try {
    const { status } = await API.auth.joinNewsletters(body);
    if (status < 200 && status >= 300) throw new Error('Something went wrong!');
    dispatch({ type: types.JOIN_NEWSLETTERS_SUCCESS });
    closeModal();
    notification({
      type: 'success',
      message: 'Successfully!',
    });
  } catch (e) {
    notification({
      type: 'error',
      message: getErrorText(e?.response?.data?.errors),
    });
    dispatch({
      type: types.JOIN_NEWSLETTERS_FAILURE,
      payload: e?.response?.data?.errors,
    });
  }
};
