import notification from '../../services/notification';

export const validate = valid => {
  const [validPass, validEmail] = valid;

  if (!validPass) {
    notification({
      type: 'error',
      message: 'Password invalid!',
    });
    return;
  }
  if (!validEmail) {
    notification({
      type: 'error',
      message: 'Email invalid!',
    });
    return;
  }

  return true;
};
