import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/reducer';
import tfaReducer from './tfa/reducer';
import errorsReducer from './error/reducer';
import modal from './modal/reducer';
import users from './users/reducer';
import personsReducer from './persons/reducer';
import eventsReducer from './events/reducer';
import layout from './layout/reducer';
import collectivesReducer from './collectives/reducer';
import venuesReducer from './venues/reducer';
import { encryptor } from './encryptor';

const authConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
  transforms: [encryptor],
};

const rootReducers = combineReducers({
  auth: persistReducer(authConfig, authReducer),
  tfa: tfaReducer,
  modal,
  errors: errorsReducer,
  users,
  personsReducer,
  eventsReducer,
  collectivesReducer,
  venuesReducer,
  layout,
});

export default rootReducers;
