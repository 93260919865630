import { useState } from 'react';
import * as helpers from '../../../services/helpers';

const useInputChange = () => {
  const [input, setInput] = useState({
    email: '',
    password: '',
  });
  const [validPass, setValidPass] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const valid = [validPass, validEmail];

  const validator = (name, value) => {
    if (name === 'password') {
      if (!value) {
        setValidPass(true);
        return;
      }
      setValidPass(helpers.passwordValid(value));
    } else if (name === 'email') {
      if (!value) {
        setValidEmail(true);
        return;
      }
      setValidEmail(helpers.emailValid(value));
    }
  };

  const handleInputChange = ({ currentTarget: { name, value } }) => {
    validator(name, value);

    setInput({
      ...input,
      [name]: value,
    });
  };
  const handleClearInput = () => {
    setInput({ email: '', password: '' });
  };

  return [input, handleInputChange, handleClearInput, valid];
};
export default useInputChange;
